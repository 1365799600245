import { requestNative } from 'js/lib/api/api-request'
import { openCloudinaryWidget, isCloudinaryWidgetOpen, preloadCloudinaryWidget } from 'js/lib/cloudinary'
import $ from 'cash-dom'

initialize()

async function initialize() {
  const imgUploads = $('.js-c-media-list-uploader')
  if (!imgUploads.length) return

  preloadCloudinaryWidget()

  imgUploads.each((_, el) => {
    const imgUpload = $(el)

    imgUpload.on('click', '.js-c-media-list-uploader__upload-btn', event => {
      event.preventDefault()

      const $btn = $(event.currentTarget)
      const disabledClass = 'tw-opacity-60 !tw-cursor-cursor-wait'

      $btn.addClass(disabledClass).attr('disabled', true)
      handleImageUpload(el, () => $btn.removeClass(disabledClass).removeAttr('disabled'))
    })
  })
}

async function handleImageUpload(element, callback) {
  if (isCloudinaryWidgetOpen()) return

  let error = false
  const {
    tag,
    allowedFormats,
    maxImageWidth,
    maxImageHeight,
    minImageWidth,
    minImageHeight,
    resourceType,
    postToUrl,
  } = JSON.parse(element.dataset.clientState)

  const results = await openCloudinaryWidget({
    // The uploadPreset config is important so that the image gets uploaded without initial transformations
    // TODO: this should be an environment variable
    uploadPreset: 'no_transforms',
    tags: [tag],
    multiple: true,
    sources: ['local', 'url'],
    maxFileSize: 25 * 100000, // 25 MB
    resourceType,
    allowedFormats,
    maxImageWidth,
    maxImageHeight,
    minImageWidth,
    minImageHeight,
  })

  if (results.length) {
    try {
      await submitUpdates(postToUrl, results)
      window.location.reload()
    } catch (error) {
      window.alert('Error')
    }
  }
  
  callback()
}

async function submitUpdates(postToUrl, results) {
  const media = results.map(upload => {
    const { info } = upload
    return {
      media_type: info.resource_type,
      cdn_public_id: info.public_id,
      source_url: info.url,
      width: info.width,
      height: info.height,
      bytes: info.bytes,
      original_format: info.format,
    }
  })

  await requestNative({ url: postToUrl, data: { media } })
}
