import I18n from 'js/lib/i18n.js.erb'
import entries from 'object.entries'
import { createFlash } from 'components/flash/component'
import { register } from 'js/lib/create-component'
import { useValidation } from 'js/lib/components/use-validation'

register('createPayementRequestModal', function (stateJSON) {
  const { authenticity_token, redirect_path, preselected_path, payment_request_paths, prefill } = JSON.parse(stateJSON)

  function pwyc() {
    return this.pwyc
  }

  async function handleSubmit() {
    try {
      this.submitting = true
      this.validateInputs()

      if (this.countValidationErrors() > 0) {
        this.submitting = false
        return
      }

      const url = payment_request_paths[this.selected_payment_path] + `?authenticity_token=${authenticity_token}`
      const data = {
        payment_request: {
          listing_id: this.listing_id,
          amount: this.amount,
          label: this.label,
          pay_what_you_can: this.pwyc,
        },
      }

      const response = await $.ajax({ url, type: 'POST', dataType: 'json', data })

      // TODO: create a better way of generating urls in js
      window.location = `${redirect_path}?type=reference&term=${response.data.uid}`
    } catch (error) {
      this.submitting = false

      const errorData = error?.responseJSON?.errors || {}
      const scope = 'modals.create_payment_request_modal.component.error_msg'

      // Mapped errors are errors that we loop through, checking if they exist. This is handy if there is a specific
      // error that doesn't have a message but we want to run a check for. We'll use the key of the matching mapped error
      // to apply the correct translation
      const mappedErrors = {
        missing_adoption_amount: errorData => errorData?.amount?.find(entry => entry.code === 'not_a_number'),
      }

      const [firstMappedError] = entries(mappedErrors)
        .map(([key, predicate]) => (predicate(errorData) ? key : undefined))
        .filter(key => key !== undefined)

      const firstError =
        Array.isArray(errorData?.base) && errorData.base.find(e => typeof e === 'object' && e.code !== undefined)

      // we get the errors in 3 ways, first by searching for an actual error message, if that's not available we check if a mapped error exists,
      // if not, then we fall back to the generic error.
      const errorMessage = firstError.message
        ? I18n.t('detail', { scope, msg: firstError.message }) : firstMappedError ? I18n.t(firstMappedError, { scope }) : I18n.t('generic', { scope })

      createFlash('alert', errorMessage)

      console.error(error)
    }
  }

  function validateInputs() {
    this.validateFeild('listing_id', () => {
      if (!this.listing_id && this.selected_payment_path === 'home_to_home_adoption_fee') {
        return 'Listing id is required'
      }
    })

    this.validateFeild('selected_payment_path', () => {
      if (!this.selected_payment_path) return 'Please select a payment path'
    })
  }

  return {
    selected_payment_path: preselected_path || undefined,
    listing_id: prefill.listing_id || '',
    amount: prefill.amount || '',
    label: prefill.label || '',
    submitting: false,
    pwyc: false,
    handleSubmit,
    validateInputs,
    ...useValidation(),
  }
})
