import $ from 'cash-dom'

$(document).ready(function() {
  const $select = $('.js-c-select-status');
  const form = document.getElementById('status-change-form');

  $select.on('change', function() {
    const selectedValue = $select.val();
    const groupId = $select.data('group-id');
    const caseId = $select.data('case-id');

    if (selectedValue === 'closed') {
      window.location.href = `/admin/groups/${groupId}/rms/case/${caseId}/closures/new`;
    } else {
      form.submit();
    }
  });
});