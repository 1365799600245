import I18n from 'js/lib/i18n.js.erb'
import { createFlash } from 'components/flash/component'
import { CLOSE_BUTTON_SELECTOR, MODAL_SELECTOR, closeAllModals } from 'components/modal/component'

;(function ($) {
  $(document).ready(function () {
    var $container = $('.update_status_container')
    var $updateStatusButton = $container.find('.button--update-status')
    var $statusSelect = $container.find('select.listing-status')
    var $statusReason = $container.find('.listing-status-reason')

    // if the select is disabled then no point running this js
    if ($statusSelect.attr('disabled')) return

    $container
      .find('select.listing-status')
      .on('change', function () {
        dataLayer.push({
          listingStatus: $(this).val(),
        })
      })
      .trigger('change')

    $statusReason.on('change', function () {
      var status = $(this).val()
      var listingStatus = $(this).data('current')

      if (listingStatus !== status) {
        $updateStatusButton.removeClass('disabled').prop('disabled', false)
      } else {
        $updateStatusButton.addClass('disabled').prop('disabled', true)
      }
    })

    $updateStatusButton.on('click', function () {
      var $statusOption = $statusSelect.find('option:selected')
      var status = $statusOption.val()
      var url = $statusOption.data('url')
      var method = $statusOption.data('method').toUpperCase()
      var publicOnHoldReasons = ['reviewing_applications', 'adoption_pending']

      var data = {
        reason: $statusReason.val(),
      }
      var callbacks = {
        on_hold: function (listingData) {
          var listings = {}

          listings[listingData.id] = listingData.name

          $.onHoldNotificationModal.init(listings)
        },
        rehomed: function (listingData) {
          var listings = {}

          listings[listingData.id] = listingData.name

          $.adoptionNotificationModal.init(listings, {
            noEnquiriesCallback: afterAdoption,
          })

          $(`.adopted_notification ${CLOSE_BUTTON_SELECTOR}`).on('click', afterAdoption)
        },
      }

      $updateStatusButton.addClass('disabled').prop('disabled', true)

      $.ajax({
        url: url,
        method: method,
        data: data,
        success: function () {

          const statusBanner = document.querySelectorAll('.js-c-listing-status-banner')
          const statusBannerInner = '.js-c-listing-status-banner__banner'
          let statusText = I18n.t(`listings.listing.status-banner.${status}`)
          
          if (status === 'on_hold' && publicOnHoldReasons.includes($statusReason.val())) {
            const reason = I18n.t(`listings.statuses.on_hold_reasons.${$statusReason.val()}`)
            statusText = I18n.t('listings.listing.status-banner.on_hold_with_reason', { reason })
          }
          
          if (callbacks[status]) {
            callbacks[status].call(this, $statusSelect.data())
          }
          
          // Update status Banners
          statusBanner.forEach(banner => {
            const statusBannerInnerElement = banner.querySelector(statusBannerInner)
            statusBannerInnerElement.innerHTML = statusText
            banner.classList.toggle('hidden', status === 'active')
          })
          
          // legacy update show/hide classes
          if (status == 'active') {
            // TODO: these classes are way too generic
            $('.active, .active-button').removeClass('hidden')
            $('.not-active, .not-active p, .pet-listing__status p, .not-active-button .js-c-listing-status-banner').addClass('hidden')
          } else {
            $('.active, .pet-listing__status p, .not-active p, .active-button').addClass('hidden')
            $('.not-active').removeClass('hidden')
            $('.not-active-button').removeClass('hidden')
          }

          $updateStatusButton.data('selector', status)
          $statusSelect.trigger('change')
          createFlash('notice', I18n.t('listings.admin_box.statuses.flash.success'))
        },
        error: function () {
          createFlash('alert', I18n.t('listings.admin_box.statuses.flash.error'))
          $statusSelect.val($updateStatusButton.data('selector')).trigger('change')
          $statusReason.val($statusReason.data('current')).trigger('change')
        },
      })
    })

    $(MODAL_SELECTOR).on('click', '.close-button', () => closeAllModals())
  })

  function afterAdoption() {
    $('.confetti-canvas').show()
    window.RestartConfetti()
    setTimeout(window.DeactivateConfetti, 1200)
  }
})(jQuery)
