const filtersContainer = document.querySelector('.js-c-rms-search-filters')
const filtersButton = document.getElementById('rms-filters--button');
const filtersPanel = document.getElementById('rms-filters--panel');

let isMenuExpanded = false

const toggleMenu = () => {
  filtersPanel.classList.toggle('tw-hidden')
  isMenuExpanded = !filtersPanel.classList.contains('tw-hidden')
  filtersButton.setAttribute('aria-expanded', isMenuExpanded)
  
  if (isMenuExpanded) {
    document.addEventListener('keydown', handleEscapeKey)
  } else {
    document.removeEventListener('keydown', handleEscapeKey)
  }
}

const handleEscapeKey = (event) => {
  if (event.key === 'Escape') {
    console.log("esc")
    toggleMenu()
  }
}

if (filtersButton && filtersPanel) {
  filtersButton.addEventListener('click', toggleMenu);
}

if (filtersContainer) {

  const filters = document.querySelectorAll('.js-c-rms-search-filter--option')
  const resetButton = document.querySelector('.js-c-rms-search-filters--reset')

  filters.forEach((filter) => {
    filterInit(filter)
  })

  if (resetButton) {
    resetButton.addEventListener('click', function() {
      filters.forEach((filter) => {
        resetCheckbox(filter)
      })
    })
  }
}

function filterInit(filter) {
  if (!filter) {
    return
  }
  
  const label = filter.nextElementSibling
  const form = filter.closest('form')
  
  const filterStateChange = () => {
    if (form) {
      form.submit()
    }
  }

  filter.addEventListener('click', filterStateChange)
  label.addEventListener('click', filterStateChange)
}

function resetCheckbox(filter) {
  const input = filter.previousElementSibling
  if (input.checked == true) {
    filter.setAttribute('aria-checked', 'false')
    input.checked = false
  }
}
