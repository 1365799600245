import I18n from 'js/lib/i18n.js.erb'
import { getRecaptchaResult } from 'components/recaptcha_widget/component'
import { track } from 'js/cards/common/subscribe_newsletter'

;(function ($) {
  $(document).ready(function () {
    var $enquiryForm = $('.listing--enquiry-form')
    var $phoneNumber = $('#listings_enquiry_phone_number')
    var $submitButton = $('.listing--enquiry-form-submit')
    var $newsletterCheckbox = $('#listings_enquiry_subscribe_to_newsletter')

    if ($enquiryForm.length === 0) {
      return
    }

    $phoneNumber.intlTelInput({
      initialCountry: 'AU',
      formatOnInit: true,
      hiddenInput: $phoneNumber.prop('name'),
      autoPlaceholder: 'aggressive',
      customPlaceholder: function () {
        return I18n.t('intl_tel_input.placeholder')
      },
    })

    $submitButton.on('click', async function(e) {
      e.preventDefault();
      let result = await getRecaptchaResult() // always run recaptcha *after* validation

      if (result) {
        if($newsletterCheckbox.is(':checked')) {
          track('subscribe', 'Enquiry form')
        }
        $enquiryForm.submit();
      }
    })
  })
})(jQuery)
