import { createAnalyticsGroup } from 'js/lib/analytics'

const banners = document.querySelectorAll('.js-c-article-donation-banner-grey')

function initBanner(banner) {
  if (!banner) return
  
  const donateButton = banner.querySelectorAll('.js-c-article-donation-banner-grey__cta')
  const track = createAnalyticsGroup(`donation-modal_button`)
  console.log(donateButton)
  
  donateButton.forEach(cta => {
    cta.addEventListener('click', () => {
      track('click', 'donation-modal_buttonclick')
    })
  })
}

banners.forEach((banner) => {
  initBanner(banner)
})
