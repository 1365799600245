function initSearch(search) {

  const input = search.querySelector('.js-c-search--input')
  const clearButtonContainer = search.querySelector('.js-c-search--clear-input')
  
  if (!search || !clearButtonContainer) return

  const clearButton = clearButtonContainer.querySelector('button')

  function handleResize() {
    positionClearButton(search, input, clearButtonContainer)
  }

  // Initial positioning
  positionClearButton(search, input, clearButtonContainer)

  // Update position on window resize
  window.addEventListener('resize', handleResize)
  // Handle clear button click
  if (clearButton) {
    clearButton.addEventListener('click', function() {
      handleClearButtonClick(input, search)
    })
  }
}

function positionClearButton(searchContainer, input, clearButtonContainer) {

    // Only show the clear button if this function runs
    clearButtonContainer.classList.replace('tw-hidden', 'tw-flex')

  const inputRect = input.getBoundingClientRect()
  const clearRect = clearButtonContainer.getBoundingClientRect()

  // Set the clear button container height to the input height for Y positioning
  clearButtonContainer.style.height = (inputRect.height) + 'px'
  // Set the X positioning of the clear button container
  clearButtonContainer.style.left = (inputRect.width - clearRect.width) + 'px'
  // Add padding to the input when the clear button is visible
  input.style.paddingRight = ( clearRect.width ) + 'px'
}

function handleClearButtonClick(input, search) {
  console.log("check")
  // Clear the input field
  input.value = ''

  // Submit the form
  const form = search.closest('form')
  if (form) {
    form.submit()
  }
}

function initializeSearch() {
  const searchInputs = document.querySelectorAll('.js-c-search')
  
  if (!searchInputs) return

  searchInputs.forEach(search => {
    initSearch(search)
  })
}

initializeSearch()
