const dropdowns = document.querySelectorAll('.js-c-ui-dropdown')

function initDropdowns(dropdown) {
  const trigger = dropdown.querySelector('.js-c-ui-dropdown-trigger')
  const content = dropdown.querySelector('.js-c-ui-dropdown-content')
  const activeTriggers = document.querySelectorAll('[data-dropdown-active-class]')

  if (!trigger || !content ) {
    return
  }

  let isExpanded = false
  let keydownListenerAdded = false

  const focusableElements = content.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
  const lastFocusableElement = focusableElements[focusableElements.length - 1]

  if (focusableElements.length ) {
    lastFocusableElement.addEventListener('keydown', function(event) {
      if (event.key === 'Tab' && !event.shiftKey) {
        event.preventDefault()
        trigger.focus()
      }
    })
  }

  function toggleActiveTriggers(expanded, dropdown) {
    activeTriggers.forEach((activeTrigger) => {
      if (activeTrigger.closest('.js-c-ui-dropdown') === dropdown) {
        const activeClassValue = activeTrigger.getAttribute('data-dropdown-active-class')
        
        if (activeClassValue) {
          const activeClasses = activeClassValue.split(' ')
          
          if (expanded) {
            activeClasses.forEach((activeClass) => {
              activeTrigger.classList.add(activeClass)
            })
          } else {
            activeClasses.forEach((activeClass) => {
              activeTrigger.classList.remove(activeClass)
            })
          }
        }
      }
    })
  }
  

  trigger.addEventListener('click', function (event) {
    event.preventDefault()
    isExpanded = !content.classList.toggle('hidden')
    trigger.setAttribute('aria-expanded', isExpanded)

    if (isExpanded) {
      setMaxHeight(content, true)
      setLeftRightPosition(content, true)
      document.addEventListener('click', closeDropdownOutside)
      window.addEventListener('resize', handleWindowResize)
      addKeydownListener()
      toggleActiveTriggers(true, dropdown) 
    } else {
      setMaxHeight(content, false)
      setLeftRightPosition(content, true)
      document.removeEventListener('click', closeDropdownOutside)
      window.removeEventListener('resize', handleWindowResize)
      removeKeydownListener()
      toggleActiveTriggers(false, dropdown) // Remove active class from triggers
    }
  })

  const closeElements = dropdown.querySelectorAll('[data-dropdown="close"]')
  closeElements.forEach(function (closeElement) {
    closeElement.addEventListener('click', function (event) {
      event.preventDefault()
      closeDropdown()
    })
  })

  function closeDropdown() {
    if (isExpanded) {
      content.classList.add('hidden')
      trigger.setAttribute('aria-expanded', false)
      isExpanded = false
      setMaxHeight(content, false)
      setLeftRightPosition(content, true)
      document.removeEventListener('click', closeDropdownOutside)
      document.removeEventListener('keydown', handleEscapeKey)
      window.removeEventListener('resize', handleWindowResize)
      removeKeydownListener()
      toggleActiveTriggers(false, dropdown) // Remove active class from triggers
    }
  }

  function closeDropdownOutside(event) {
    if (!dropdown.contains(event.target) && !trigger.contains(event.target)) {
      closeDropdown()
    }
  }

  function handleEscapeKey(event) {
    if (event.key === 'Escape') {
      closeDropdown()
    }
  }

  function handleWindowResize() {
    if (isExpanded) {
      setMaxHeight(content, true)
      setLeftRightPosition(content, true)
    }
  }

  function addKeydownListener() {
    if (!keydownListenerAdded) {
      document.addEventListener('keydown', handleKeyDown)
      keydownListenerAdded = true
    }
  }

  function removeKeydownListener() {
    if (keydownListenerAdded) {
      document.removeEventListener('keydown', handleKeyDown)
      keydownListenerAdded = false
    }
  }

  function handleKeyDown(event) {
    if (event.key === 'Escape') {
      closeDropdown()
    }
  }

  function setMaxHeight(dropdown, isExpanded) {
    const distanceFromTop = dropdown.getBoundingClientRect().top
    const viewportHeight = window.innerHeight
    const maxHeight = viewportHeight - distanceFromTop
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

    if (dropdown.offsetHeight >= maxHeight && isExpanded) {
      document.body.style.overflow = 'hidden'
      document.body.style.paddingRight = `${scrollbarWidth}px`
    } else {
      document.body.style.overflow = 'auto'
      document.body.style.paddingRight = '0'
    }
    dropdown.style.maxHeight = `${maxHeight}px`
  }

  function setLeftRightPosition(dropdownContent, isExpanded) {
    if (!isExpanded) return

    const dropdownContentWidth = dropdownContent.getBoundingClientRect().width
    const dropdownContentOffsetLeft = dropdownContent.getBoundingClientRect().left
    const dropdownContentOffsetRight = window.innerWidth - (dropdownContentOffsetLeft + dropdownContentWidth)

    const dropdown = dropdownContent.closest('.js-c-ui-dropdown')
    const dropdownWidth = dropdown.getBoundingClientRect().width
    const dropdownOffsetLeft = dropdown.getBoundingClientRect().left
    const dropdownOffsetRight = window.innerWidth - (dropdownOffsetLeft + dropdownWidth)

    const unstick = (dropdownContentWidth + Math.min(dropdownOffsetLeft, dropdownOffsetRight)) < window.innerWidth 

    if (dropdownContentOffsetLeft <= 0) {
      dropdownContent.style.left = '0'
      dropdownContent.style.position = 'fixed'
    } 
    if (dropdownContentOffsetRight <= 0) {
      dropdownContent.style.right = '0'
      dropdownContent.style.position = 'fixed'
    }
    if (unstick) {
      dropdownContent.style.right = ''
      dropdownContent.style.left = ''
      dropdownContent.style.position = ''
    }
  }
}

dropdowns.forEach((dropdown) => {
  initDropdowns(dropdown)
})
