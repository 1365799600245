
function twoFactor(wrapper) {

  // TODO: 
  // It would be better ux/accessibility practice if
  // on load the tab sequence went: first 2fa input, resend, recover
  
  // if a user inputs a char into the first input the next is then focusable, and so on
  // if pasted in the first it shoud fill all inputs

  const formWrapper = document.querySelectorAll(wrapper)

  if (!formWrapper.length) { return }

  const form = document.querySelector(wrapper + " form")

  const inputs = document.querySelectorAll('.js-c-two-factor-input__input')
  const firstInput = document.getElementById('js-c-two-factor-input__input-0')
  const submit = document.getElementById('js-verification-submit')
  const hiddenField = document.getElementById("verification_code")

  function validateInputs(input) {
    // If we wanted to do any clientside validation
    // This is where we would do it
    if (input.value === "") {
      input.classList.add("invalid")
      return false
    }
    input.classList.remove("invalid")
    return input.value.length > 1 ? input.value[0] : input.value
  }

  function focusElement(el) {
    el.focus()
    el.select()
  }
  // Focus first input on page load
  focusElement(firstInput)

  addEvents()

  function addEvents() {
    form.addEventListener("input", checkInput())
    form.addEventListener("click", focusInput())
    form.addEventListener("keydown", pressKey())
    form.addEventListener("submit", submitCode())

    function checkInput(thisInput) {
      return function handleNumericInput(e) {
        const value = validateInputs(e.target)

        if (e.target.value.length > 1) {
          pasteCode(e)
        } else if (value !== false) {
          e.target.value = value
          focusNextInput.call(thisInput, e.target)
        } else {
          e.target.value = ""
        }

        if (inputsValid().areInputsValid) {
          submit.focus()
          // Auto submit causes a redirect glitch 
          // When the user hits enter it attempts to submit twice
          // Causing an expired code error
          // submit.click()
        }
      }
    }

    function focusInput() {
      return function handleClickInput(e) {
        if (e.target.tagName == "INPUT") {
          focusElement(e.target);
        }
      }
    }

    function pasteCode(e) {
      let copyCode = e.data || e.target.value
      if (!copyCode) return
      if (copyCode.length === 1) return
      let copyCodeTrimmed = copyCode.split(' ').join('').substring(0, 6)

      for (let i = 0; i < inputs.length; i++) {
        inputs[i].value = copyCodeTrimmed[i]
        inputs[i].focus()
      }
      setTimeout(() => {
        // Autosubmit when pasted
        submit.focus()
        submit.click()
        for (let input of inputs) {
          if (validateInputs(input) === false) {
            focusElement(input)
            break
          }
        }
      }, 0)
    }

    function pressKey(thisInput) {
      return function handleEventKeys(e) {
        handleArrowKeys(e, thisInput)
        handleDelete(e, thisInput)
      }
    }

    function handleArrowKeys(e, thisInput) {
      if (e.key == "ArrowRight") {
        e.preventDefault();
        focusNextInput.call(thisInput, e.target);
      }
      if (e.key == "ArrowLeft") {
        e.preventDefault();
        focusPrevInput.call(thisInput, e.target);
      }
    }

    function handleDelete(e, thisInput) {
      if (e.key == "Backspace") {
        e.target.value = ""
        e.target.classList.add("invalid")
        focusPrevInput.call(thisInput, e.target)
      }
    }
  }

  function focusNextInput(currentInput) {
    if (currentInput.nextElementSibling) {
      focusElement(currentInput.nextElementSibling)
    }
  }

  function focusPrevInput(currentInput) {
    if (currentInput.previousElementSibling) {
      focusElement(currentInput.previousElementSibling)
    }
  }

  const inputsValid = () => {
    let areInputsValid = true
    let inputCode = ""

    for (let input of inputs) {
      if (validateInputs(input) === false) {
        areInputsValid = false
      }
      inputCode += input.value
    }
    return { areInputsValid, inputCode }
  }

  function submitCode() {
    return function handleSubmit() {
      if (inputsValid().areInputsValid) {
        hiddenField.value = inputsValid().inputCode
      }
      // else {} could do a clientside validation here to 
      // Prevent form submission until the inputs are valid 
    }
  }
}

twoFactor('.js-c-two-factor-input')
